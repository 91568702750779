








































import Vue from 'vue';
import {
  coccMtdsResource_1_project_branchs_categoryByxmTypeReport,
  coccMtdsResource_1_project_constructsBySequenceNbrReportTree,
} from '@/api/projectDetail';
import { ReportTreeItem } from '@/interface/product';
import { mapGetters } from 'vuex';
import { subtract } from '@/utils/index';

export default Vue.extend({
  name: 'reportForms',
  data() {
    const reportFormsTree: ReportTreeItem[] = [];
    const selectedKeys: string[] = [];
    const expandedKeys: string[] = [];
    return {
      reportFormsTree,
      fileUrl: '',
      selectedKeys,
      expandedKeys,
      loading: true,
    };
  },
  watch: {
    sequenceNbr: function (val, oldVal) {
      if (val !== oldVal) {
        this.getReportFormTree();
      }
    },
    listGenerateSuccess: function (val) {
      if (val) {
        this.getReportFormTree();
      }
    },
  },
  computed: {
    ...mapGetters(['sequenceNbr', 'bidSequenceNbr', 'listGenerateSuccess']),
  },
  created() {
    this.getReportFormTree();
  },
  methods: {
    /**
     * 获取报表树数据
     */
    getReportFormTree(reportFormData = null) {
      if (reportFormData) {
        this.otherReportTreeHandler(reportFormData);
        return;
      }
      if (!this.sequenceNbr) return '';
      coccMtdsResource_1_project_constructsBySequenceNbrReportTree(
        this.bidSequenceNbr || this.sequenceNbr
      ).then((res) => {
        if (res.status === 200) {
          this.otherReportTreeHandler(res.result.children);
        }
      });
    },
    otherReportTreeHandler(treeData) {
      this.loading = false;
      const reportFormsTree = this.handlerReportTree(treeData);
      this.reportFormsTree = reportFormsTree;
      if (reportFormsTree.length) {
        this.$nextTick(() => {
          this.selectedKeys = [reportFormsTree[0].key as string];
          this.getViewReport(reportFormsTree[0]);
        });
      }
    },
    /**
     * 处理报表树数据主要加唯一key值
     */
    handlerReportTree(data: ReportTreeItem[], tempNum = 0) {
      return data.map((item: ReportTreeItem, index) => {
        tempNum += index;
        if (item.children.length) {
          item.children = this.handlerReportTree(item.children, tempNum);
        }
        item.key = item.parentId + item.name + item.reportName + tempNum;
        return item;
      });
    },
    clickTree(
      keys: string[],
      { node }: { node: { dataRef: ReportTreeItem; expanded: boolean } }
    ) {
      const data = node.dataRef;
      if (!data.children.length) {
        this.getViewReport(data);
      }
      const nodeKey = node.dataRef.key as string;
      if (!node.expanded) {
        this.expandedKeys.push(nodeKey);
      } else {
        this.expandedKeys = this.expandedKeys.filter((key: string) => {
          return key !== nodeKey;
        });
      }
    },
    /**
     * 获取报表
     */
    getViewReport(data: ReportTreeItem) {
      if (!data.storageFormat) return;
      const url = data.storageFormat === 'H' ? data.hengBanUrl : data.shuBanUrl;
      if (!url) return;
      this.fileUrl = `${
          process.env.VUE_APP_YSF_URL
      }/static/pdf/web/viewer.html?file=${encodeURIComponent(url)}`;
    },
  },
});
